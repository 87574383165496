import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutEN from "../../composants/layout/en/LayoutEN"
import {
  obtenirImages,
  obtenirImage,
  obtenirPage,
} from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"
import Lien from "../../composants/navigation/Lien"
import Image from "../../composants/image/Image"
import Section from "../../composants/layout/Section"

export const requete = graphql`
  {
    pageAPropos: allPagesCompilationYaml(
      filter: { code: { eq: "aProposEN" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(filter: { code: { in: ["tf1"] } }) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: { code: { in: ["numeriqueResponsable"] } }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["moiMicro", "moi"] } }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function About() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageAPropos")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageTresPetit",
    "imagesPortrait"
  )

  return (
    <LayoutEN page={page}>
      <Section
        id="section-numerique-responsable"
        titre="A low-consumption website for more sustainable digital technology"
      >
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <p>
              A militant message to introduce this page. Our bulimia for digital
              is not without environmental consequences. In 2025, it should
              represent <strong>6%</strong> of CO₂ emissions in the world (
              <Lien urlExterne="https://www.greenit.fr/wp-content/uploads/2019/10/2019-10-GREENIT-etude_EENM-rapport-accessible.VF_.pdf">
                source
              </Lien>
              ), the equivalent of the emissions of a country like India.
            </p>
            <p>
              Digital technology and its connected objects will also be
              responsible for the <strong>destruction of our starry sky</strong>
              . The satellite Internet will trigger the deployment of tens of
              thousands of satellites in the very near future. These will be all
              new bright spots in the night sky. The spectacle of a celestial
              vault observed through binoculars devoid of any satellite will
              soon be a distant memory.
            </p>
            <p>
              No website can be green. However, this one was designed to limit
              the IT resources required to its operation in order to reduce its
              environmental impact:
            </p>
            <ul>
              <li>
                <strong>The reduced weight of the pages</strong> (696 kb on
                average, that is to say 3 times less than the world average) (
                <Lien urlExterne="https://httparchive.org/reports/page-weight">
                  source
                </Lien>
                ) and loading images only on demand allows fast accessibility
                anywhere in the world regardless of network quality and
                regardless of the age of the computer or the phone.
              </li>
              <li>
                <strong>No database</strong> is used.
              </li>
              <li>
                Hosting is carried out in France in an efficient datacenter, on{" "}
                <strong>a very low power server</strong> and manually optimized.
              </li>
            </ul>
            <p>
              The responsibility of the site also applies to{" "}
              <strong>personal data</strong>. They are only used to monitor
              visits by page and they are self-collected, self-hosted and
              anonymized by software that is fully compatible with GDPR and
              open-source (
              <Lien urlExterne="https://matomo.org/">Matomo Analytics</Lien>
              ). You can exclude yourself from the counts by unchecking the
              option on this <Lien codePageCible="mentionsLegalesEN">page</Lien>
              .
            </p>
          </div>
          <Image
            image={obtenirImage(images, "numeriqueResponsable")}
            afficherLegende={false}
            langue={page.langue}
            afficherOmbre={false}
          />
        </div>
      </Section>
      <Section titre="Me :-)">
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <p>
              My name is Bastien, I was born in 1982 and I am a software
              engineer.
            </p>
            <p>
              I’ve always been head in the air… in every sense of the word. My
              interest in astronomy started when I was a child with the comet
              Hyakutake in 1996. Then came various astronomical gifts: a bedroom
              planetarium, a book on constellations…
            </p>
            <p>
              Then the astronomical events followed and made me passionate,
              until the day I look for the first time in a telescope. I could
              see Mars who was then closest to the Earth, in 2003. I never
              thought that we would see so many things there: the polar caps,
              the seas… So I bought myself my first telescope. It was completely
              manual, I had to learn all the constellations by heart to hope to
              use it.
            </p>
            <p>
              Also very passionate by photography, I saw astrophotography as a
              way to bring together my passions. I started in 2005 with the
              first digital SLRs and a small Chinese telescope. The first tests
              are magical: the nebulae appear in color, as in the photos of
              Hubble. I am now settled in Brittany in Finistère where the cloudy
              gaps allow me to observe the starry sky ;-)
            </p>
            <p>
              Astrophotography is a real school of patience. The perfect
              conditions are rare. You have to face the clouds, wind, humidity,
              cold, fatigue, light pollution. The failures are numerous. Objects
              can only be photographed for 1 to 2 months per year, and only on
              moonless nights. When the conditions are right, you have to spend
              one or more nights awake, watching the equipment. Some images
              presented in this gallery have more than 70 hours of cumulative
              exposure.
            </p>
            <p>
              Hoping that my few works will make you, like me, “head in the
              air”…
            </p>
          </div>
          <Image
            image={obtenirImage(images, "moi")}
            afficherLegende={false}
            langue={page.langue}
            afficherOmbre={true}
          />
        </div>
      </Section>
      <Section titre="References">
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <ul className="liste-avec-espace-entre-items">
              <li>
                12/01/23 : The image of{" "}
                <Lien codePageCible="voieLacteeENTelescopeHESSII">HESS II</Lien>{" "}
                telescope illustrates the December{" "}
                <Lien urlExterne="https://www.spektrum.de/inhaltsverzeichnis/jubilaeum-20-jahre-h-e-s-s-sterne-und-weltraum-12-2023/2099391">
                  front cover
                </Lien>{" "}
                of German physics magazine « Sterne und Weltraum » (« Stars and
                space »).
              </li>
              <li>
                03/01/22 : The image of{" "}
                <Lien codePageCible="voieLacteeENTelescopeHESSII">HESS II</Lien>{" "}
                telescope illustrates the March{" "}
                <Lien urlExterne="https://onlinelibrary.wiley.com/toc/15213943/2022/53/2">
                  front cover
                </Lien>{" "}
                of German physics magazine « Physik in unserer zeit » (« The
                physics of our times »).
              </li>
              <li>
                10/28/21 : The image{" "}
                <Lien codePageCible="orionZoomEN">Orion 360</Lien> illustrate
                the book{" "}
                <Lien urlExterne="https://www.amazon.com/Bang-Brian-May/dp/1787398277">
                  Bang!!
                </Lien>
                , written by rockstar Brian May (guitarist for the band Queen)
                and Sir Patrick Moore.
              </li>
              <li>08/05/21 : Report for french television TF1.</li>
              <li>03/21/21 : Astrobin, image of the day.</li>
              <li>
                11/25/20 :{" "}
                <Lien urlExterne="http://www.museedelaphoto.fr/">
                  Acquisition of a collection of 9 prints by the Museum of
                  French Photography
                </Lien>
                .
              </li>
              <li>
                09/11/20 :{" "}
                <Lien urlExterne="https://www.rmg.co.uk/whats-on/astronomy-photographer-year/galleries/2020/stars-and-nebulae">
                  Astrophotographer of the year 2020 : finalist
                </Lien>
                .
              </li>
              <li>04/25/20 : Astrobin, image of the day.</li>
              <li>
                06/18/18 :{" "}
                <Lien urlExterne="http://planetarynebulae.net/EN/page_np.php?id=76">
                  Discovery of the FoGl1 candidate planetary nebula
                </Lien>
                .
                <ul>
                  <li>
                    <Lien urlExterne="http://planetarynebulae.net/documentation/LEDU 125.pdf">
                      Article published in the french journal “L’Astronomie” of
                      March 2019
                    </Lien>
                    , published by the Astronomical Society of France.
                  </li>
                  <li>
                    <Lien codePageCible="decouverteNebuleusePlanetaire">
                      Story of the discovery.
                    </Lien>
                  </li>
                  <li>
                    <Lien urlExterne="http://planetarynebulae.net/tableau_spectres/FoGl_1_fiche.pdf">
                      Spectral analysis.
                    </Lien>
                  </li>
                </ul>
              </li>
              <li>
                12/12/17 :{" "}
                <Lien urlExterne="http://www.ifa.hawaii.edu/~reipurth/newsletter/newsletter300.pdf">
                  Cover image of the 300th edition (25 years) of the scientist
                  letter “Star formation newsletter”
                </Lien>{" "}
                (Hawaii Institute of Astronomy).
              </li>
              <li>
                11/19/17 :{" "}
                <Lien urlExterne="http://www.photo-montier.org/fiche-exposant/escursia-3/">
                  Conference for the International Photo Festival of
                  Montier-en-Der
                </Lien>
                .
              </li>
              <li>
                08/15/17 :{" "}
                <Lien urlExterne="https://apod.nasa.gov/apod/ap170815.html">
                  Astronomy Picture of the Day (NASA)
                </Lien>
                .
              </li>
              <li>
                04/26/15 :{" "}
                <Lien urlExterne="https://photos.cala.asso.fr/displayimage.php?pid=2917">
                  French planetarium of Vaulx-en-Velin, first prize of the photo
                  competition of the festival “Oufs d’astro”
                </Lien>
                .
              </li>
              <li>
                2013 - 2015 : Software engineer for camera manufacturer Atik.
              </li>
            </ul>
          </div>
          <Image
            image={obtenirImage(images, "moiMicro")}
            afficherLegende={false}
            langue={page.langue}
            afficherOmbre={true}
          />
        </div>
        <Image
          image={obtenirImage(images, "tf1")}
          afficherLegende={false}
          langue={page.langue}
          afficherOmbre={true}
        />
      </Section>
      <Section titre="Exhibitions">
        <h3>2020 - 2018</h3>
        <ul className="liste-avec-espace-entre-items">
          <li>July - August 2020 : NightScapades festival (Lourdes, 65).</li>
          <li>July - August 2019 : NightScapades festival (Lourdes, 65).</li>
          <li>
            April 2019 - April 2020 : the Seasons of Photography in the great
            Forest of Saint-Hubert (Belgium).
          </li>
          <li>
            September 2018 :{" "}
            <Lien urlExterne="https://www.exposaves.be/portfolio/gloaguen-sabine-foucher-bastien/">
              Namur AVES International Photo Festival
            </Lien>{" "}
            (Belgium).
          </li>
        </ul>
        <h3>2017</h3>
        <ul className="liste-avec-espace-entre-items">
          <li>
            November 2017 :{" "}
            <Lien urlExterne="https://www.photo-montier.org/fiche-exposant/nos-tetes-etoiles/">
              21st International Wildlife and Nature Photography Festival of
              Montier-en-Der (France)
            </Lien>
            .
          </li>
        </ul>
      </Section>
    </LayoutEN>
  )
}
